<template>
    <vue-pure-lightbox class="lightbox">
    <section class="resetdone has-text-centered">
        <div class="container">
            <table class="reg-form has-text-centered">
            <tr>
                <td class="form-header">
                    <p class="org-description is-size-5"></p>
                </td>
            </tr>
            <tr>
                <td class="form-content">
                    
                    <p class="org-description is-size-5">{{ message }}</p>
                    <div><img class="center-image" v-bind:src="require('../assets/' + image)" width="100px"></div>
                    <button class="button submit is-primary is-outlined" @click="submit">
                        <span>{{ next }}</span>
                    </button>

                </td>
            </tr>
            </table>
        </div>
    </section>
    </vue-pure-lightbox>
</template>

<script>
    export default {
        name: "resetdone",
        props: {
            status: {
                type: String,
                default: "2"
            }
        },
        computed: {
            message: function() {
                var msg = "Something went terribly wrong. Please contact locker@3df.io.";
                if (this.status == "1") {
                    msg = "Congrats! You have successfully reset your password!";
                }

                if (this.status == "2") {
                    msg = "Password reset failed. Please contact locker@3df.io.";
                }

                if (this.status == "3") {
                    msg = "Server is unreachable. Please contact locker@3df.io";
                }
                
                if (this.status == "4") {
                    msg = "Post password reset failed.";
                }
                return msg;
            },
            image: function() {
                var img = "";
                if (this.status == "1") {
                    img = "smiley-lock.svg";
                } else {
                    img = "sad-lock.svg";
                }
                return img;
            },
            next: function() {
                var msg = "";
                if (this.status == "1") {
                    msg = "OK";
                }

                if (this.status == "2") {
                    msg = "Retry";
                }

                if (this.status == "3") {
                    msg = "Retry";
                }

                if (this.status == "4") {
                    msg = "Login Manually";
                }
                return msg;
            }
        },
        methods: {
            submit() {
                requestAnimationFrame(() => {
                    if (this.status == "1") {

                        this.$router.replace({ path: "/login" });
                    }

                    if (this.status == "2") {
                        this.$router.replace({ path: "/send/reset" });
                    }

                    if (this.status == "3") {
                        this.$router.replace({ path: "/send/reset" });
                    }

                    if (this.status == "4") {
                        this.$router.replace({ path: "/login" });
                    }
                });
            },
        }
    }
</script>

<style lang="scss">
    $main-color: #aac734;
    .lightbox {
        width: 100%; height: 100%;
        background: rgba(0, 0, 0, .4);
    }
    .resetdone {
        padding-top: 55px;
        padding-bottom: 100px;
        width: 100%;
    }
    .center-image {
        padding-top: 20px;
        align-content: center;
    }
    .container {
        width: 100%;
        justify-content: center;
    }
    .submit {
        margin-top: 30px;
        text-align: center;
    }
    .reg-form {
        width: 100%;
        max-width: 600px;
        border-radius: 10px;
        box-shadow: 0px 2px 30px rgba(0,0,0,0.3);
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
    }
    .form-header {
        font-weight: bold;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px;
        background: $main-color;
    }
    .form-content {
        background: #F5F5F5;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 30px;
        padding-left: 10%;
        padding-right: 10%;
    }
    .form-content .label {
        font-size: 0.78rem;
        text-align: left;
        padding-left: 5px;
        font-weight: 400;
    }
</style>